































































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { ActionMethod } from 'vuex';
import BaseTextInput from '@improve/common-components/src/components/core/BaseTextInput.vue';
import { debounce } from 'lodash';
import BaseTeamCard from '@improve/common-components/src/components/widgets/BaseTeamCard.vue';
import Team from '@improve/common-utils/src/model/Team';
import StatsData from '@improve/common-utils/src/model/StatsData';
import { MenuOption } from '@improve/common-utils/src/types/MenuOption';
import BaseButton from '@improve/common-components/src/components/widgets/BaseButton.vue';
import BaseMenu from '@improve/common-components/src/components/widgets/BaseMenu.vue';
import TabContent from '@improve/common-components/src/components/helpers/TabContent.vue';

@Component({
  name: 'Teams',
  components: {
    BaseButton,
    BaseMenu,
    BaseTextInput,
    TabContent,
    BaseTeamCard
  }
})
export default class Teams extends Vue {
  @Getter organizationalUnits!: Array<Team>;

  @Getter teams!: Array<Team>;

  @Getter teamStatsByID!: Map<string, StatsData>;

  @Action fetchTeamStats!: ActionMethod;

  menuOptions: MenuOption[] = [
    {
      title: this.$t('menu.virtualTeam').toString(),
      value: 'virtualTeamValue'
    },
    {
      title: this.$t('menu.orgTeam').toString(),
      value: 'orgTeamValue'
    }];

  private search: string | null = null;

  private debounceSearchInput = debounce((event: string) => {
    this.search = event;
  }, 300);

  get filteredTeams(): Team[] {
    const teams = [...this.organizationalUnits, ...this.teams];
    if (!this.search || this.search.length < 3) {
      return teams;
    }
    if (this.search && this.search.length) {
      const searchLowerCase = this.search!.toLowerCase();
      return teams
        .filter((_: Team) => _.name?.toLowerCase().includes(searchLowerCase));
    }
    return teams;
  }

  async created(): Promise<void> {
    const teamIds = this.filteredTeams.map((t) => t.id);
    await this.fetchTeamStats(teamIds);
    this.$forceUpdate();
  }

  fetchTeamStat(team: Team): StatsData {
    if (!this.teamStatsByID || !this.teamStatsByID.has(team.id!)) {
      return new StatsData();
    }
    return this.teamStatsByID.get(team.id!)!;
  }

  addVirtualTeam(): void {
    this.$router.push({ name: 'CreateTeam', params: { type: 'virtual' } });
  }

  addOrgTeam(): void {
    this.$router.push({ name: 'CreateTeam', params: { type: 'unit' } });
  }

  editTeam(teamId: string): void {
    this.$router.push({ name: 'EditTeam', params: { id: teamId } });
  }

  async onMenuSelect(option: MenuOption): Promise<void> {
    switch (option.value) {
      case 'virtualTeamValue': {
        this.addVirtualTeam();
        break;
      }
      case 'orgTeamValue': {
        this.addOrgTeam();
        break;
      }
      default: {
        // just to solve eslint error
        break;
      }
    }
  }
}
