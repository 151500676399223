

















































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import Team, { TEAM_TYPE } from '@improve/common-utils/src/model/Team';
import BasePill from './BasePill.vue';

@Component({
  name: 'BaseTeamCard',
  components: {
    BasePill
  }
})
export default class BaseTeamCard extends Vue {
  @Prop({ default: null }) readonly team!: Team;

  @Prop({ default: 46 }) size !: number;

  @Prop({ default: 'Team name' }) teamName !: string;

  @Prop({ default: '300' }) cardWidth!: string;

  @Prop({ default: '85' }) cardHeight!: string;

  @Prop({ default: 'far fa-user-friends' }) faIcon !: string;

  @Prop({ default: 'far fa-user' }) personIcon !: string;

  @Prop({ default: 'far fa-cog' }) ticketIcon !: string;

  @Prop({ default: 0 }) numberOfTeamMembers !: number;

  @Prop({ default: null }) readonly delegateId!: string;

  @Prop({ default: 0 }) numberOfTickets !: number;

  get isVirtualTeam(): boolean {
    return this.team.type === TEAM_TYPE.VIRTUAL_TEAM;
  }

  goToTeamOverview(): void {
    if (this.delegateId) {
      this.$router.push({
        name: 'TeamImprovesTab',
        params: { id: this.delegateId }
      });
    }
  }
}
